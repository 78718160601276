.hero-banner {
  position: relative;
  width: 100%;

  img {
    height: auto !important;
    object-fit: cover;
    width: 100% !important;
  }

  .hero-background {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    z-index: 1;

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      outline: none;
    }
  }

  .hero-banner-content {
    position: relative;
    height: 100%;
    width: 100%;
    color: white;
    z-index: 2;
  }
}

.left-gradient {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(51, 51, 51, 0.3) 42.79%,
      rgba(50, 50, 50, 0) 102.28%
    );
    z-index: 1;
  }
}

.center-gradient {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      270deg,
      rgba(51, 51, 51, 0) 4%,
      rgba(51, 51, 51, 0.3) 51.83%,
      rgba(50, 50, 50, 0) 100%
    );
  }
}

.right-gradient {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      270deg,
      rgba(51, 51, 51, 0.3) 42.79%,
      rgba(50, 50, 50, 0) 102.28%
    );
  }
}
