@import '~@bayada/assets/scss/abstracts/breakpoints.module';

.app-nav {
  width: 100%;

  .app-nav-wrap {
    min-width: 0px;
    background: white;
    transition: background 0s ease-in-out;
    will-change: background;

    &.has-transparent-nav {
      background: linear-gradient(
        180deg,
        rgba(var(--primary-black), 0.5) 0%,
        rgba(var(--primary-black), 0.1) 50%,
        rgba(255, 255, 255, 0) 100%,
        rgba(255, 255, 255, 0) 100%
      );

      @include breakpoint-up(lg) {
        background: transparent;
      }

      .primary-navbar {
        transition: background 0.1s ease-in-out;
        will-change: background;
        background: var(--ba-primary-black);
        background: linear-gradient(
          180deg,
          rgba(var(--primary-black), 0.5) 0%,
          rgba(var(--primary-black), 0.1) 50%,
          rgba(255, 255, 255, 0) 100%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }

  .top-bar {
    min-height: 36px;
    width: 100%;
    justify-content: space-between;
    background-color: var(--ba-gray-100);

    a {
      font-family: inherit;
    }

    .navigation-list {
      min-width: 0;
      overflow-x: auto;
      &:hover {
        .nav-item {
          opacity: 0.85;
        }
      }

      .nav-item {
        color: var(--ba-primary-black);
        margin: 0 10px;
        padding: 0;
        transition:
          color 0.2s ease-in-out,
          opacity 0.2s ease-in-out;
        will-change: color, opacity;

        &:hover {
          background: transparent;
          color: var(--ba-primary-black);
          text-decoration: underline;
          cursor: pointer;
          opacity: 1;
        }
      }

      li {
        flex-shrink: 0;
      }
    }
  }

  .primary-navbar {
    justify-content: space-between;
    height: 68px;

    .navigation-list {
      .nav-item {
        border-radius: 4px;
        cursor: pointer;
        color: var(--ba-primary-black);
        position: relative;
        transition: color 0.2s ease-in-out;
        will-change: color;
        z-index: 1;

        &:after {
          background: var(--ba-primary-black);
          opacity: 0;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          z-index: 0;
          pointer-events: none;
          transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          will-change: opacity;
        }

        button {
          z-index: 2;
        }

        .nav-link {
          &:after {
            background: var(--ba-primary-black);
            opacity: 0;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            z-index: 0;
            transition: opacity ease-in-out 0.2s;
            will-change: opacity;
            pointer-events: none;
          }

          &:hover,
          &:active,
          &:focus,
          &.active {
            color: var(--white);

            &:after {
              background: var(--ba-primary-black);
              opacity: 1;
            }
          }
        }

        .nav-link,
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          border-radius: 4px;
        }

        &:last-child {
          margin-right: 0;
        }

        .app-menu-list {
          max-height: 70vh;
          overflow-y: auto;

          a {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.has-transparent-nav {
  top: 0;
  z-index: 11;

  .primary-navbar {
    .navigation-list {
      .nav-item {
        color: white;
        position: relative;
        border-radius: 4px;

        &:after {
          opacity: 0.2;
        }

        &:last-child {
          margin-right: 0;
        }

        .menu-dropdown {
          &:focus:not(:focus-visible) {
            color: var(--white);
          }

          &:focus-visible {
            color: var(--white);
            background: var(--ba-primary-black);
          }
        }

        .nav-link {
          &:focus-within {
            color: var(--white);
            background: var(--ba-primary-black);
          }
        }
      }
    }
  }
}

.app-menu {
  right: 0px;
  top: 0px;
  color: var(--ba-primary-black);

  .app-menu-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .app-menu-body-nav {
      overflow: hidden;
      overflow-y: auto;
      border-top: solid 1px var(--ba-gray-85);
      display: flex;
      flex-direction: column;
      font-size: 56px;
      gap: 12px;
      margin-top: 66px;
      overscroll-behavior: contain;

      a {
        text-decoration: none;
        color: var(--ba-primary-black);
        font-weight: 300;
      }
    }
  }
}

.app-menu-link {
  position: relative;
  display: flex;
  align-items: center;
}

.nav-logo {
  width: 121px;
  max-width: 121px;
  height: 38px;
  max-height: 38px;
}

.subnav-block {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
  will-change: grid-template-rows;
}

.app-l-submenu--open {
  .subnav-block {
    grid-template-rows: 1fr;
  }
}

.skip-link {
  position: fixed;
  top: -9999px;
  left: -9999px;
  opacity: 1;
  pointer-events: none;
  transition: all 0.3s ease;
}

.skip-link.focused {
  top: 0;
  left: 0;
  opacity: 1;
  pointer-events: auto;
  z-index: 999999;
}
