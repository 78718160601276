@import '~@bayada/assets/scss/abstracts/breakpoints.module';

.app-site-search {
  margin-left: auto;
  margin-right: auto;
  * {
    font-family: frutiger, ui-serif, system-ui !important;
  }
  @include breakpoint-up(lg) {
    max-width: 80%;
  }
  .gsc-search-box {
    &.gsc-search-box-tools {
      margin-bottom: 26px;
      position: relative;
      margin-right: 75px;
      width: auto;
    }
  }
  table.gsc-search-box {
    table-layout: fixed;
    td {
      padding: 0;
      &.gsib_b {
        display: none;
      }
    }
    > tbody {
      > tr {
        > td {
          &.gsc-input {
            width: 100%;
          }
          &.gsc-clear-button {
            display: table-cell;
            background: none;
            width: 60px;

            > .gsc-clear-button {
              display: flex;
              width: 24px;
              height: 24px;
              position: relative;
              text-indent: 9999px;
              overflow: hidden;
              margin: 0 auto;
              padding: 0;
              background-image: none;
              &::before {
                content: '';
                background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cg clip-path="url(%23clip0_6172_66476)"%3E%3Cpath d="M18.75 5.25L5.25 18.75" stroke="%23757575" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M18.75 18.75L5.25 5.25" stroke="%23757575" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_6172_66476"%3E%3Crect width="24" height="24" fill="white"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A');
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
              &:focus-visible {
                outline: 2px solid var(--ba-primary-black);
              }
              &:hover {
                background-image: none;
              }
            }
          }
        }
      }
    }
  }
  .gsc-search-button-v2 {
    position: absolute;
    top: 0;
    right: -75px;
    width: 58px;
    height: 58px;
    border: none;
    padding: 0;
    background: var(--ba-primary-black);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow ease 0.2s;

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4 12.2727H19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M12.8635 6.27271L18.9999 12.2727L12.8635 18.2727" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
      display: block;
    }
    &:hover,
    &:focus {
      background: var(--ba-primary-black);
      box-shadow:
        0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
    &:focus-visible {
      outline: 2px solid var(--ba-primary-black);
    }
    svg {
      display: none;
    }
  }
  .gsc-control-cse {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    .gsc-table-result {
      display: flex;
      flex-direction: column-reverse;
      @include breakpoint-up(lg) {
        flex-direction: row-reverse;
      }
      .gs-snippet {
        @include breakpoint-up(lg) {
          margin-top: 10px;
        }
      }
    }
    .gs-image-box {
      width: auto;
      padding: 0;
      position: relative;
      margin-top: 8px;
    }

    .gsc-control-cse .gs-result .gs-title {
      margin-bottom: 8px !important;
    }
    .gs-webResult.gs-result a.gs-title:link,
    .gs-webResult.gs-result a.gs-title:link b,
    .gs-imageResult a.gs-title:link,
    .gs-imageResult a.gs-title:link b {
      color: var(--ba-primary-black);
      font-weight: 600;
      font-size: 20px;
    }
    .gs-webResult div.gs-visibleUrl {
      color: var(--ba-primary-black);
      font-weight: 600;
    }
    .gs-webResult:not(.gs-no-results-result):not(.gs-error-result) .gs-snippet,
    .gs-fileFormatType {
      color: var(--ba-primary-black);
      opacity: 0.8;
    }
  }
}
form.gsc-search-box {
  border: none !important;
  box-shadow:
    0px 4px 10px 0px rgba(0, 0, 0, 0.14),
    0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  &:focus,
  &:focus-within {
    outline: 2px solid var(--ba-blue-200);
  }
  input.gsc-input {
    height: 58px !important;
    box-shadow: none;
    border: none !important;
    padding: 0 16px !important;
    background: none !important;
  }
  .gsc-input-box {
    border-radius: 4px;
    border: none;
    padding: 0;
  }
}
table.gsc-search-box {
  margin: 0 !important;
  td {
    &.gsc-input {
      padding: 0 !important;
    }
  }
}

.gsc-results {
  &.gsc-webResult {
    display: block;
  }
}
.gsc-results-wrapper-visible {
  .gsc-above-wrapper-area {
    padding: 0;
    padding-bottom: 12px;
    margin-bottom: 40px;

    .gsc-above-wrapper-area-container {
      .gsc-result-info {
        padding: 0;
        font-size: 13px;
        color: var(--ba-gray-425);
      }
      .gsc-orderby-container {
        .gsc-orderby-label {
          font-size: 13px;
          color: var(--ba-gray-425);
          padding: 0;
          margin-right: 4px;
        }
        .gsc-option-menu {
          top: 33px !important;

          .gsc-option-menu-item {
            padding-left: 14px;
            padding-right: 14px;
          }
        }
      }
      .gsc-option-menu-container {
        .gsc-selected-option-container {
          border: none;
          box-shadow: none;
          background: var(--ba-gray-75);
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 9px 8px;

          .gsc-selected-option {
            font-size: 13px;
            color: var(--ba-primary-black);
          }
          .gsc-option-selector {
            background-image: url('data:image/svg+xml,%3Csvg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9 1L5 5L1 1" stroke="%23232323" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
            padding: 0;
            width: 15px;
            height: 15px;
            position: static;
            margin: 0;
            flex-shrink: 0;
          }
        }
      }
    }
  }
  .gsc-wrapper {
    .gsc-resultsbox-visible {
      .gsc-expansionArea {
        .gsc-webResult {
          padding: 0;
          border: none;

          + .gsc-webResult {
            margin-top: 26px;
          }
          .gsc-thumbnail-inside {
            margin-bottom: 4px;
            .gs-title {
              overflow: visible;
              font-size: 21px;
              font-weight: 700;
              line-height: 1.238095238;
              color: var(--ba-blue-400);
              b {
                color: inherit;
              }
              &:focus-visible {
                outline: 2px solid var(--ba-primary-black);
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .gsc-url-top {
            margin-bottom: 8px;
            .gs-bidi-start-align {
              font-size: 13px;
              color: var(--ba-gray-425);
              font-weight: 400;
              line-height: 1.384615385;
            }
          }
          .gsc-table-result {
            gap: 12px;

            .gsc-table-cell-snippet-close {
              font-size: 13px;
              line-height: 1.384615385;
              color: var(--ba-primary-black);
            }
            .gsc-table-cell-thumbnail {
              .gs-image-box {
                margin-top: 0;
                float: none;
                width: 100%;
                max-width: 110px;

                .gs-image {
                  width: 100%;
                  display: block;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }
      }
      .gsc-cursor-box {
        margin: 40px 0 0 0;

        .gsc-cursor {
          display: flex;
          align-items: center;
          gap: 4px;

          .gsc-cursor-page {
            font-size: 13px;
            font-weight: 400;
            color: var(--ba-gray-425);
            margin: 0;
            min-width: 20px;
            min-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;

            &.gsc-cursor-current-page,
            &:hover {
              color: var(--ba-primary-black);
            }
          }
          .gsc-cursor-container-previous {
            flex-grow: initial;
            margin-left: auto;
          }
          .gsc-cursor-next-page {
            margin-left: 0;
          }
          .gsc-cursor-container-next {
            flex-grow: initial;
            margin-right: auto;
          }
        }
      }
      .gcsc-find-more-on-google {
        display: flex;
        margin-left: 0;
        flex-wrap: wrap;

        .gcsc-find-more-on-google-magnifier {
          display: none;
        }
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          margin-right: 8px;
          background-image: url('data:image/svg+xml,%3Csvg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cg clip-path="url(%23clip0_6172_66690)"%3E%3Cpath d="M8.75 15C12.2018 15 15 12.2018 15 8.75C15 5.29822 12.2018 2.5 8.75 2.5C5.29822 2.5 2.5 5.29822 2.5 8.75C2.5 12.2018 5.29822 15 8.75 15Z" stroke="%230000CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M13.1696 13.1696L17.5 17.5" stroke="%230000CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id="clip0_6172_66690"%3E%3Crect width="20" height="20" fill="white"/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A');
        }
        > span {
          font-size: 15px;
          margin-right: 4px;
        }
        .gcsc-find-more-on-google-query {
          font-weight: 700;
        }
      }
      .gcsc-more-maybe-branding-root {
        margin-top: 24px;
        display: flex;
        align-items: center;

        @include breakpoint-down(md) {
          flex-direction: column;
          align-items: flex-start;
        }

        .gcsc-find-more-on-google-branding {
          float: none;
          margin-left: auto;
        }
      }
      .gcsc-find-more-on-google-root {
        .gcsc-more-maybe-branding-box {
          margin: 16px 0 0 0;
        }
      }
    }
  }
}
.gs-no-results-result {
  margin-block: 8px;
  border: 1px solid var(--ba-gray-200);
  border-radius: 8px;
  min-height: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .gs-snippet {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    color: var(--ba-gray-900);
    font-size: var(--t-25-36);
    font-weight: 700 !important;
    text-align: center;
    line-height: 1.2;

    @include breakpoint-up(md) {
      font-size: var(--t-46-54);
    }
  }
}

.gsc-results {
  width: 100% !important;
}
