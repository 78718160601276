@import '~@bayada/assets/scss/abstracts/breakpoints.module';

.contentful-richtext-group {
  ol {
    list-style: auto;
    padding-left: 2rem;

    + p {
      margin-top: 0.75rem;
    }
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 0.75rem;
    }
    li {
      + li {
        margin-top: 0.25rem;
      }
    }
    + hr {
      margin-top: 0.5rem;
    }
  }
  ul {
    padding-left: 2rem;

    @include breakpoint-up(lg) {
      padding-left: 2.5rem;
    }

    + p {
      margin-top: 0.75rem;
    }
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 0.75rem;
    }

    li {
      position: relative;
      p {
        line-height: 1.7;
      }
      &:before {
        content: '';
        position: absolute;
        top: 10px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        border: 2px solid var(--ba-gray-425);
        background-color: var(--ba-gray-425);
        left: -1.25rem;
      }
      &.text-right {
        &:before {
          left: auto;
          right: -1.25rem;
        }
      }
    }
    li {
      + li {
        margin-top: 0.25rem;
      }
    }
    &.text-right {
      padding-right: 1rem;
      padding-left: 0;
      @include breakpoint-up(lg) {
        padding-right: 2.5rem;
      }
      li {
        &:before {
          left: auto;
          right: -1.25rem;
        }
      }
    }
    + hr {
      margin-top: 0.5rem;
    }
  }
  ul > li > ul > li:before {
    background-color: transparent;
  }
  ul > li > ul > li > ul > li:before {
    background-color: var(--ba-gray-425);
  }
  ul > li > ul > li > ul > li > ul > li:before {
    background-color: transparent;
  }
  ul > li > ul > li > ul > li > ul > li > ul > li:before {
    background-color: var(--ba-gray-425);
  }
  ul > li > ul > li > ul > li > ul > li > ul > li > ul > li:before {
    background-color: transparent;
  }
  ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li:before {
    background-color: var(--ba-gray-425);
  }
  p {
    white-space: pre-wrap;
    font-size: var(--t-16-17);
    font-family: var(--font-primary);
    letter-spacing: 0;

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 2rem;
    }

    b {
      font-weight: 700;
    }
    i {
      font-style: italic;
    }
    u {
      text-decoration: underline;
    }
    + p,
    + span {
      padding-left: 0;
      padding-right: 0;
    }
    + p {
      margin-top: 0.75rem;
    }
    + ul {
      margin-top: 0.25rem;
    }
    + ol {
      margin-top: 0.25rem;
    }
    + table {
      margin-top: 0.25rem;
    }
    + hr {
      margin-top: 0.5rem;
    }
  }
  span {
    + p {
      padding-left: 0;
      padding-right: 0;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    i {
      font-style: italic;
    }
    + p {
      margin-top: 0.25rem;
    }
    + hr {
      margin-top: 0.25rem;
    }
  }

  > h1 {
    font-size: var(--t-46-64);
    line-height: 1.09;
    letter-spacing: -1.28px;
    font-family: var(--font-secondary);
  }
  > h2 {
    font-size: var(--t-33-46);
    line-height: 1.1;
    letter-spacing: -1.08px;
    font-family: var(--font-secondary);
  }
  > h3 {
    font-size: var(--t-25-36);
    line-height: 1.27;
    letter-spacing: -0.36px;
    font-family: var(--font-secondary);
  }
  > h4 {
    font-size: var(--t-22-28);
    line-height: 1.14;
    letter-spacing: -0.36px;
    font-family: var(--font-secondary);
  }
  > h5 {
    font-size: var(--t-18-21);
    line-height: 1.23;
    letter-spacing: 0px;
    font-family: var(--font-secondary);
  }
  > h6 {
    font-size: var(--t-16-17);
    line-height: 1.41;
    letter-spacing: 0px;
    font-family: var(--font-primary);
  }
  a {
    color: var(--primary-shade);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline-offset: 2px;
      outline: 2px solid var(--black);
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--white);

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 0.75rem;
    }
    + p {
      margin-top: 0.25rem;
    }

    th {
      p {
        font-weight: 600;
        color: var(--ba-primary-black);
        font-size: var(--t-15);
        line-height: 1.41;
      }
    }
    th,
    td {
      border: 1px solid var(--ba-gray-175);
      padding: 12px 24px 0 16px;
      text-align: left;
    }
  }
  blockquote {
    p {
      padding: 24px;
      background-color: var(--ba-gray-95);
    }
  }
}
