.donate-cta-wrapper {
  button {
    background: var(--primary-shade);
    margin: unset !important;
    min-height: 50px !important;
    width: auto !important;
    font-family: 'Inter', sans-serif, 'frutiger', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1 !important;
    padding: 0.813rem 1.5rem;
    background: linear-gradient(96deg,
        var(--primary-shade) 100%,
        var(--primary-shade) 100%,
        var(--primary-shade) 100%,
        var(--primary-shade) 100%);

    &:hover {
      background: linear-gradient(96deg,
          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 0%,
          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 19.27%,
          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 66.15%,
          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 100%);
    }

    &:focus {
      background: var(--ba-btn-focus-bg);
    }

    &.mc-donate-button {
      ~.mc-donate-button {
        display: none !important;
      }
    }
  }
}