.alert-container {
  background: transparent;
  a {
    color: var(--ba-primary-black);
  }
}
.alert-primary {
  background-color: var(--primary-shade);
  color: var(--white);
  bottom: 0;
  top: auto;
  z-index: 999;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
}

.alert-secondary {
  background-color: var(--ba-gray-900);
  color: var(--white);
}
.alert-default {
  background: transparent;
}
