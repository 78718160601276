.ctalink {
  font-size: var(--t-15);
  line-height: 1.6rem;
  padding: 0.75rem 1rem;
  text-decoration: none;
  color: var(--ba-primary-black);
  :hover {
    color: var(--primary-shade);
  }
}

.modal-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  width: clamp(200px, 80%, 700px);
  height: auto;
  max-height: clamp(200px, 50%, calc(100% - 32px));
}
