@import '~@bayada/assets/scss/abstracts/breakpoints.module';

.ba-hover-expand-card {
  position: relative;
  min-height: 0;
  padding-top: 100%;
  height: 0;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 35.94%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 1;
    transition: opacity ease 0.3s;
    will-change: opacity;

    @include breakpoint-up(md) {
      opacity: 0.2;
    }
  }

  &:hover,
  &:focus-within {
    &:before {
      @include breakpoint-up(md) {
        opacity: 1;
      }
    }
  }
}

.slider-cta-wrap {
  > div {
    display: flex;
    justify-content: center;
  }
}
